import {Card, Stack, Typography} from '@mui/material';
import {Cell, Pie, PieChart} from 'recharts';
import {ConfidenceChip} from '../ConfidenceChip';

function compileData(data) {
  const result = {
    on_track: {value: 0, color: '#2f7c31'},
    at_risk: {value: 0, color: '#e3d025'},
    off_track: {value: 0, color: '#d32f2f'},
    no_check_in: {value: 0, color: 'gray'},
  };

  data.forEach((objective) => {
    objective.key_results.forEach((keyResult) => {
      if (keyResult.check_in.length === 0) {
        result.no_check_in.value += 1;
      } else {
        result[keyResult.check_in[0].confidence].value += 1;
      }
    });
  });

  return Object.keys(result)
    .filter((key) => result[key].value > 0)
    .map((key) => ({
      name: key,
      value: result[key].value,
      color: result[key].color,
    }));
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PiePeriodChart(props) {
  const {objectives} = props;
  const data = compileData(objectives);

  return (
    <Card
      sx={{
        backgroundColor: '#F4F4F4',
        borderRadius: '8px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
        width: '100%',
      }}
    >
      <Stack alignItems="center" gap={1}>
        <Typography variant="h6" fontWeight={700} fontSize="18px">
          Net Confidence
        </Typography>
        <Stack
          direction="row"
          gap={4}
          alignItems="center"
          width="100%"
          justifyContent="center"
          flexWrap="wrap"
        >
          <PieChart width={140} height={140}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={70}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <Stack gap={1.5}>
            {['on_track', 'at_risk', 'off_track', 'no_check_in'].map(
              (confidence) => (
                <ConfidenceChip
                  key={confidence}
                  confidence={confidence}
                  variant="filled"
                />
              )
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

export default PiePeriodChart;
