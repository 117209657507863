import {Form} from '@/components/generic/forms/Form';
import {FormSubmitButton} from '@/components/generic/forms/FormButtons';
import {
  SelectSingleInput,
  TextInput,
} from '@/components/generic/forms/FormFields';
import {useGetAreas} from '@/hooks';
import {useCreateTeam, useUpdateTeam} from '@/hooks/useGetTeams';
import formatOptions from '@/util/formatOptions';
import Close from '@mui/icons-material/Close';
import {Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {useState} from 'react';

function TeamForm(props) {
  const {onClose, team} = props;
  const [, setStateSelected] = useState(null);

  const {data: areaResults} = useGetAreas();

  const teamUpdateMutation = useUpdateTeam();

  const teamCreateMutation = useCreateTeam();

  return (
    <Form
      handleSubmit={(values) => {
        team
          ? teamUpdateMutation.mutateAsync({...team, ...values})
          : teamCreateMutation.mutateAsync(values);
        onClose && onClose();
      }}
    >
      <Card
        sx={{
          height: '100%',
          m: '0 auto',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <CardHeader
          title="Time"
          subheader={team?.name}
          action={
            <IconButton aria-label="settings" onClick={onClose}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Stack gap={1} mt={1}>
            <TextInput name="name" label="Nome" initialValue={team?.name} />
            <SelectSingleInput
              options={formatOptions(areaResults)}
              name="area_id"
              label="Area"
              size="small"
              initialValue={team?.area_id}
              changeListener={(value) => {
                setStateSelected(value);
              }}
            />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            paddingX: '48px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormSubmitButton color="primary" variant="contained">
            {team ? 'Editar' : 'Criar'} time
          </FormSubmitButton>
        </CardActions>
      </Card>
    </Form>
  );
}

export default TeamForm;
