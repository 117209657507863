import {Card, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import React, {useEffect, useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

dayjs.extend(weekOfYear);

const CheckInBarChart = ({objectives}) => {
  const currentWeek = dayjs().week();
  const [checkIns, setCheckIns] = useState({});

  useEffect(() => {
    const checkInsPerWeek = {};
    objectives.forEach((objective) => {
      objective.key_results.forEach((keyResult) => {
        keyResult.check_in.forEach((checkIn) => {
          const week = dayjs(checkIn.created_at).week();

          if (week <= currentWeek) {
            if (!checkInsPerWeek[week]) {
              checkInsPerWeek[week] = 0;
            }
            checkInsPerWeek[week]++;
          }
        });
      });
    });
    setCheckIns(checkInsPerWeek);
  }, [currentWeek, objectives]);

  const data = Object.keys(checkIns).map((week) => {
    const startOfWeek = dayjs()
      .week(parseInt(week))
      .startOf('week')
      .format('DD/MM');
    const endOfWeek = dayjs()
      .week(parseInt(week))
      .endOf('week')
      .format('DD/MM');
    return {
      name: `${startOfWeek} - ${endOfWeek}`,
      checkIns: checkIns[parseInt(week)],
    };
  });

  return (
    <Card
      sx={{
        backgroundColor: '#F4F4F4',
        borderRadius: '8px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '16px',
        width: '100%',
      }}
    >
      <Stack alignItems="center" gap={2}>
        <Typography variant="h6" fontWeight={700} fontSize="18px">
          Check-ins por semana
        </Typography>
        <BarChart
          width={300}
          height={170}
          data={data}
          margin={{
            top: 5,
            bottom: 5,
            left: -25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="checkIns" fill="#8884d8" />
        </BarChart>
      </Stack>
    </Card>
  );
};

export default CheckInBarChart;
