import { useGetAreas } from '@/hooks';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { AreaForm } from '../AreaForm';
import AreaListRow from './AreaListRow';

function AreaList() {
  const {data: areasResults} = useGetAreas();

  const [openCreateArea, setOpenCreateArea] = useState(false);

  const handleOpen = () => {
    setOpenCreateArea(true);
  };

  const handleClose = () => {
    setOpenCreateArea(false);
  };

  return (
    <Card sx={{padding: '8px 16px'}}>
      <CardHeader title="Áreas" />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Icone</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areasResults?.map((area) => (
                <AreaListRow key={area.id} area={area} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="text"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{marginTop: 1}}
        >
          nova área
        </Button>
        <Modal
          open={openCreateArea}
          onClose={handleClose}
          aria-labelledby="kr-form-modal-title"
          aria-describedby="kr-form-modal-description"
        >
          <Box
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: '80%',
              maxWidth: '700px',
              padding: '20px',
            }}
          >
            <AreaForm onClose={handleClose} />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default AreaList;
