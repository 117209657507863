import {useDeleteRequest} from '@/hooks/base_hooks';
import {TableCell, TableRow} from '@mui/material';
import dayjs from 'dayjs';
import {ActionsButton} from '../ActionsButton';
import {PeriodForm} from '../PeriodForm';

function PeriodListRow(props) {
  const {period} = props;

  const deleteMutation = useDeleteRequest(['period']);

  return (
    <TableRow key={period.id}>
      <TableCell component="th" scope="row">
        {period.name}
      </TableCell>
      <TableCell align="right">
        {dayjs(period.start_date).format('DD/MM/YYYY')}
      </TableCell>
      <TableCell align="right">
        {dayjs(period.end_date).format('DD/MM/YYYY')}
      </TableCell>
      <TableCell align="right">
        <ActionsButton object={period} deleteMutation={deleteMutation}>
          <PeriodForm period={period} />
        </ActionsButton>
      </TableCell>
    </TableRow>
  );
}

export default PeriodListRow;
