import {Form} from '@/components/generic/forms/Form';
import {FormSubmitButton} from '@/components/generic/forms/FormButtons';
import {DateInput, TextInput} from '@/components/generic/forms/FormFields';
import {useCreatePeriod, useUpdatePeriod} from '@/hooks/useGetPeriods';
import Close from '@mui/icons-material/Close';
import {Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';

function PeriodForm(props) {
  const {onClose, period} = props;

  const periodUpdateMutation = useUpdatePeriod();

  const periodCreateMutation = useCreatePeriod();

  return (
    <Form
      handleSubmit={(values) => {
        const formatValues = {
          ...values,
          start_date: values.start_date.format('YYYY-MM-DD'),
          end_date: values.end_date.format('YYYY-MM-DD'),
        };
        period
          ? periodUpdateMutation.mutateAsync({...period, ...formatValues})
          : periodCreateMutation.mutateAsync(formatValues);
        onClose && onClose();
      }}
    >
      <Card
        sx={{
          height: '100%',
          m: '0 auto',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <CardHeader
          title="Ciclo"
          subheader={period?.name}
          action={
            <IconButton aria-label="settings" onClick={onClose}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Stack gap={1} mt={1}>
            <TextInput name="name" label="Nome" initialValue={period?.name} />
            <DateInput
              name="start_date"
              label="Data inicial"
              initialValue={dayjs(period?.start_date)}
            />
            <DateInput
              name="end_date"
              label="Data final"
              initialValue={dayjs(period?.end_date) ?? dayjs().add(3, 'month')}
            />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            paddingX: '48px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormSubmitButton color="primary" variant="contained">
            {period ? 'Editar' : 'Criar'} ciclo
          </FormSubmitButton>
        </CardActions>
      </Card>
    </Form>
  );
}

export default PeriodForm;
