import {useDeleteRequest} from '@/hooks/base_hooks';
import {TableCell, TableRow} from '@mui/material';
import Image from 'next/image';
import {ActionsButton} from '../ActionsButton';
import {AreaForm} from '../AreaForm';

function AreaListRow(props) {
  const {area} = props;

  const deleteMutation = useDeleteRequest(['area']);

  return (
    <TableRow key={area.id}>
      <TableCell component="th" scope="row">
        {area.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {area?.description}
      </TableCell>
      <TableCell component="th" scope="row">
        {area?.icon && (
          <Image src={area?.icon} alt={area?.name} width={40} height={40} />
        )}
      </TableCell>
      <TableCell align="right">
        <ActionsButton object={area} deleteMutation={deleteMutation}>
          <AreaForm area={area} />
        </ActionsButton>
      </TableCell>
    </TableRow>
  );
}

export default AreaListRow;
