import { useGetAreas, useGetTeams } from '@/hooks';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { TeamForm } from '../TeamForm';
import TeamListRow from './TeamListRow';

function TeamList() {
  const {data: teamsResults} = useGetTeams();
  const {data: areaResults} = useGetAreas();

  const [openCreateTeam, setOpenCreateTeam] = useState(false);

  const handleOpen = () => {
    setOpenCreateTeam(true);
  };

  const handleClose = () => {
    setOpenCreateTeam(false);
  };

  return (
    <Card sx={{padding: '8px 16px'}}>
      <CardHeader title="Times" />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Área</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamsResults?.map((team) => (
                <TeamListRow
                  key={team.id}
                  team={team}
                  areaResults={areaResults}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="text"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{marginTop: 1}}
        >
          novo time
        </Button>
        <Modal
          open={openCreateTeam}
          onClose={handleClose}
          aria-labelledby="kr-form-modal-title"
          aria-describedby="kr-form-modal-description"
        >
          <Box
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: '80%',
              maxWidth: '700px',
              padding: '20px',
            }}
          >
            <TeamForm onClose={handleClose} />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default TeamList;
