import { useGetPeriods } from '@/hooks';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { PeriodForm } from '../PeriodForm';
import PeriodListRow from './PeriodListRow';

function PeriodsList() {
  const {data: periodsResults} = useGetPeriods();

  const [openCreatePeriod, setOpenCreatePeriod] = useState(false);

  const handleOpen = () => {
    setOpenCreatePeriod(true);
  };

  const handleClose = () => {
    setOpenCreatePeriod(false);
  };

  return (
    <Card sx={{padding: '8px 16px'}}>
      <CardHeader title="Ciclos" />
      <CardContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="right">Data inicial</TableCell>
                <TableCell align="right">Data final</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {periodsResults?.map((period) => (
                <PeriodListRow key={period.id} period={period} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="text"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{marginTop: 1}}
        >
          novo ciclo
        </Button>
        <Modal
          open={openCreatePeriod}
          onClose={handleClose}
          aria-labelledby="kr-form-modal-title"
          aria-describedby="kr-form-modal-description"
        >
          <Box
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              width: '80%',
              maxWidth: '700px',
              padding: '20px',
            }}
          >
            <PeriodForm onClose={handleClose} />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default PeriodsList;
