import {useDeleteRequest} from '@/hooks/base_hooks';
import {TableCell, TableRow} from '@mui/material';
import {ActionsButton} from '../ActionsButton';
import {TeamForm} from '../TeamForm';

function TeamListRow(props) {
  const {team, areaResults} = props;

  const deleteMutation = useDeleteRequest(['team']);

  return (
    <TableRow key={team.id}>
      <TableCell component="th" scope="row">
        {team.name}
      </TableCell>
      <TableCell align="right">
        {team?.area_id && areaResults
          ? areaResults.find(({id}) => id === team?.area_id).name
          : '------'}
      </TableCell>
      <TableCell align="right">
        <ActionsButton object={team} deleteMutation={deleteMutation}>
          <TeamForm team={team} />
        </ActionsButton>
      </TableCell>
    </TableRow>
  );
}

export default TeamListRow;
