import {useGetPeriod} from '@/hooks';
import BoltIcon from '@mui/icons-material/Bolt';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import {CheckinPeriodChart} from '../CheckinPeriodChart';
import {PiePeriodChart} from '../PiePeriodChart';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

function PeriodProgress(props) {
  const {objectives, period_id} = props;

  const {data: period} = useGetPeriod(parseInt(period_id));

  const averageProgress =
    objectives.reduce((acc, objective) => acc + objective.progress, 0) /
    objectives.length;

  const today = dayjs();
  const startDate = dayjs(period?.start_date);
  const endDate = dayjs(period?.end_date);
  const totalDays = endDate.diff(startDate, 'day');
  const daysLeft = endDate.diff(today, 'day');
  const progressValue = ((totalDays - daysLeft) / totalDays) * 100;

  return (
    <Card sx={{width: {xs: '100%', md: '40%'}, height: 'fit-content'}}>
      <CardHeader
        title="Progresso do período"
        subheader={
          period_id ? (
            <Stack direction="row" alignItems="center" gap={2} marginTop={1}>
              <Typography variant="h2" fontSize="14px">
                {period?.name}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h2" fontSize="14px">
                {dayjs(period?.start_date).format('DD/MM/YYYY')} -{' '}
                {dayjs(period?.end_date).format('DD/MM/YYYY')}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="h2" fontSize="14px" marginTop={1}>
              histórico completo
            </Typography>
          )
        }
      />
      <Divider />
      <CardContent sx={{padding: '16px'}}>
        <Stack
          direction="row"
          gap={4}
          alignItems="center"
          width="100%"
          justifyContent="center"
        >
          <Card
            sx={{
              backgroundColor: '#F4F4F4',
              borderRadius: '8px',
              padding: '16px',
              width: '100%',
            }}
          >
            <Stack gap={1.5}>
              <Typography
                variant="h6"
                fontWeight={700}
                margin="0 auto"
                fontSize="18px"
              >
                Timeline
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={progressValue}
                color="success"
              />
              <Stack gap={0.5}>
                <Typography variant="h4" margin="0 auto">
                  {daysLeft}
                </Typography>
                <Typography variant="h2" fontSize="14px" margin="0 auto">
                  dias restantes
                </Typography>
              </Stack>
            </Stack>
          </Card>

          <Card
            sx={{
              backgroundColor: '#F4F4F4',
              borderRadius: '8px',
              padding: '16px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack gap={1} alignItems="center">
              <Typography variant="h6" fontWeight={700} fontSize="18px">
                Objetivos
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    color: '#129072',
                    opacity: 0.2,
                  }}
                  size={90}
                />
                <CircularProgress
                  variant="determinate"
                  color="success"
                  value={averageProgress > 100 ? 100 : averageProgress}
                  sx={{
                    position: 'absolute',
                  }}
                  size={90}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h5"
                    fontSize="28px"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(averageProgress)}%`}</Typography>
                </Box>
              </Box>
            </Stack>
          </Card>
        </Stack>
        <PiePeriodChart objectives={objectives} />
        <CheckinPeriodChart objectives={objectives} />
        <Stack
          sx={{width: '100%', marginTop: '16px'}}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            startIcon={<BoltIcon />}
            color="secondary"
            disabled
          >
            Gerar one page
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default PeriodProgress;
