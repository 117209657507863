import {Form} from '@/components/generic/forms/Form';
import {FormSubmitButton} from '@/components/generic/forms/FormButtons';
import {
  SelectSingleInput,
  TextInput,
} from '@/components/generic/forms/FormFields';
import {useCreateArea, useUpdateArea} from '@/hooks/useGetAreas';
import Close from '@mui/icons-material/Close';
import {Stack} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {useState} from 'react';

const icons = [
  {
    label: '',
    value: '/png/areas_icons/marketing.png',
    imageUrl: '/png/areas_icons/marketing.png',
  },
  {
    label: '',
    value: '/png/areas_icons/company.png',
    imageUrl: '/png/areas_icons/company.png',
  },
  {
    label: '',
    value: '/png/areas_icons/finance.png',
    imageUrl: '/png/areas_icons/finance.png',
  },
  {
    label: '',
    value: '/png/areas_icons/product.png',
    imageUrl: '/png/areas_icons/product.png',
  },
  {
    label: '',
    value: '/png/areas_icons/rh.png',
    imageUrl: '/png/areas_icons/rh.png',
  },
  {
    label: '',
    value: '/png/areas_icons/tech.png',
    imageUrl: '/png/areas_icons/tech.png',
  },
];

function AreaForm(props) {
  const {onClose, area} = props;
  const [, setStateSelected] = useState(null);

  const areaUpdateMutation = useUpdateArea();

  const areaCreateMutation = useCreateArea();

  return (
    <Form
      handleSubmit={(values) => {
        area
          ? areaUpdateMutation.mutateAsync({...area, ...values})
          : areaCreateMutation.mutateAsync(values);
        onClose && onClose();
      }}
    >
      <Card
        sx={{
          height: '100%',
          m: '0 auto',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <CardHeader
          title="Área"
          subheader={area?.name}
          action={
            <IconButton aria-label="settings" onClick={onClose}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Stack gap={1} mt={1}>
            <TextInput name="name" label="Nome" initialValue={area?.name} />
            <TextInput
              multiline
              rows={4}
              name="description"
              label="Descrição"
              initialValue={area?.description}
            />
            <SelectSingleInput
              options={icons}
              name="icon"
              label="Icone"
              initialValue={area?.icon}
              changeListener={(value) => {
                setStateSelected(value);
              }}
            />
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            paddingX: '48px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormSubmitButton color="primary" variant="contained">
            {area ? 'Editar' : 'Criar'} área
          </FormSubmitButton>
        </CardActions>
      </Card>
    </Form>
  );
}

export default AreaForm;
